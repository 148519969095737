import {
  ContractRefundAttachmentCategory,
  ContractExtensionAttachmentCategory,
} from 'constants/order.constants';
import { REVENUE_EXCEL_TYPE_LABEL } from 'pages/financial/unearnedrevenue/UnearnedRevenueList.constant';
import { AxiosInstance } from 'axios';
import { format } from 'date-fns';
import {
  TApiResponse,
  IPaginationParams,
  ESTATUS,
  PartialBy,
} from '@type/common';
import {
  IOrdersRatio,
  IOrderPeriodParam,
  IOrdersReportResponse,
  IOrderRatioDataResponse,
  EChooseType,
  IOrderMeParams,
  ITransferOrderParams,
  IGetOrdersByCustomerParams,
  IGetOrdersByCustomerResponse,
  IGetOrderRecordsResponse,
  IOrderMeResponse,
  IOrder,
  ICreateOrderRefundParams,
  IOrderRefundResponse,
  IGetOrderRefundListParams,
  ICreateContractExtensionParams,
  IContractExtensionResponse,
  IGetContractExtensionListResponse,
  IUpdateContractExtensionPayload,
  IAddContractExtensionPayload,
  IPatchOrderRefundParams,
  IUpdateLearningBarOrderByIdPayload,
  IUpdateLearningBarOrderByIdResponse,
  IUpdateTutorialOrderByIdPayload,
  IUpdateTutorialOrderByIdResponse,
  IUpdateSchoolOrderByIdPayload,
  IUpdateSchoolOrderByIdResponse,
  IGetContractsExtensionOrdersParams,
  IGetContractsExtensionOrdersResponse,
  orderState,
} from '@type/electroniccommerce/order.type';
import {
  IDeliveryParams,
  IDeliveryResponse,
  IExportDeliveryParams,
  TBatchUpdateDeliveryParams,
} from '@type/textbook.type';
import {
  TCreateDeliveriesParams,
  TCreateDeliveriesResponse,
  TDeliveryRecordsResponse,
  TEditCustomerTextbookYearAndSemesterParams,
  TEditCustomerTextbookYearAndSemesterResponse,
  TGetDeliveriesRecordsParams,
  TGetExchangeItemsByOwnerIdParams,
  TGetExchangeItemsByOwnerIdResponse,
  TGetExchangeItemsRecordLogsByIdParams,
  TGetExchangeItemsRecordLogsByIdResponse,
  TUpdateExchangeItemsPackageParams,
  TUpdateExchangeItemsPackageResponse,
} from '@type/electroniccommerce/exchange.type';
import {
  IProducts,
  IProductListParams,
  ELECTRONIC_COMMERCE_CATEGORY,
} from '@type/electroniccommerce/commodity.type';
import {
  TPaymentSettingDto,
  TPaymentSettingResponseDto,
  STRATEGY_TYPE,
  TMailSetting,
  EPayment,
  EPaymentFlow,
} from '@type/electroniccommerce/setting.type';
import {
  IPlan,
  IPlanParams,
} from 'pages/electroniccommerce/commodity/plan/types/Plan.type';
import { ISkuAttribute } from '@type/attribute.type';
import {
  IExportUnearnedRevenueListParams,
  IGetRevenuesAmtTotalParams,
  IGetUnearnedRevenueByIdResponse,
  IGetUnearnedRevenueListParams,
  IGetUnearnedRevenueListResponse,
  TGetRevenuesAmtTotalResponse,
} from '@type/revenue';
import {
  EFILE_EXTENSION,
  downloadXlsxFile,
  downloadFile,
} from 'utils/downloadFile';
import { ESource } from '@type/electroniccommerce/plan.type';
import {
  IExportPerformanceExpensePayload,
  IGetPerformanceExpensePayload,
  IGetPerformanceExpenseResponse,
} from '@type/financial/performanceExpense.type';
import { fToDate } from 'utils/formatTime';
import {
  SKU_ATTRIBUTES_GROUP,
  IAttributes,
} from '@type/electroniccommerce/attribute.type';

interface ICreateAttributesTypesParams {
  names: string[];
}

export interface ICreateAttributesParamsKey {
  name: string;
  attributesTypeId: string;
}
export interface ICreateAttributesParams {
  attributes: ICreateAttributesParamsKey[];
}

export interface IAttributesListParams extends IPaginationParams {
  name?: string; // sku名稱
  attributeTypeValue?: string | null; // 屬性類型Id
}

export interface IAttributesListByNamesParams {
  names: string[];
}

export interface ISkuParams {
  id?: string;
  name?: string; // sku 名稱
  price?: number; // 價格
  category?: ELECTRONIC_COMMERCE_CATEGORY | string; // sku 分類
  attributes?: IAttributesListParams[]; // 屬性
  previewPath?: string; // 預覽
  description?: string; // 簡介
  status?: ESTATUS; // 電商狀態
}

export interface ISku {
  id: string;
  name: string; // sku 名稱
  price: number; // 價格
  type: string; // 課程類型
  category: ELECTRONIC_COMMERCE_CATEGORY; // sku 分類
  attributes: IAttributes[]; // 屬性
  previewPath: string; // 預覽
  description: string; // 簡介
  status: ESTATUS; // 電商狀態
  // quantity?: number
}

export interface ISkuListParams extends IPaginationParams {
  name?: string; // sku名稱
  status?: ESTATUS;
  category?: ELECTRONIC_COMMERCE_CATEGORY[];
  attributesGroup?: SKU_ATTRIBUTES_GROUP[];
}

export interface IPlanQueryParams extends IPaginationParams {
  name?: string; // 搜尋名稱
  status?: string; // 啟用與否

  source?: ESource[]; // 類別 live or 學bar
}

export interface IProductsQueryParams extends IPaginationParams {
  name?: string; // 搜尋名稱
  serialNumber?: string; // 流水號
  status?: ESTATUS;
  source?: ESource[]; // 類別 live or 學bar
}

export interface ISpuToSkuOption extends ISku {
  label: string;
  value: string;
}
interface ISkusParams {
  id: string;
  quantity: number;
}
export interface IPromotionQueryParams extends IPaginationParams {
  name?: string; // 搜尋名稱
}

export interface IPromotionListParams {
  skip: number; // 頁數Offset
  limit: number; // 顯示筆數
  name: string; // 商品名稱
  serialNumber: string; // 流水號
}
export interface IStrategy {
  type: STRATEGY_TYPE;
  duration?: number;
  skus?: ISkusParams[];
  discount?: number;
}

export interface ICondition {
  productsConditionGroup?: string[];
  productsConditionBlacklistGroup?: string[];
  plansConditionGroup?: string[];
  plansConditionBlacklistGroup?: string[];
  staffsConditionGroup?: string[];
}
export interface IPromotion {
  name: string;
  isPayOff?: boolean;
  productsConditionGroup?: string[];
  plansConditionGroup?: string[];
  staffsConditionGroup?: string[];
  status: string;
  strategy: IStrategy;
  createdAt: number;
  chooseType: EChooseType;
}
export interface IPromotionParams {
  name: string;
  isPayOff?: boolean;
  productsConditionGroup?: string[];
  productsConditionBlacklistGroup?: string[];
  plansConditionGroup?: string[];
  plansConditionBlacklistGroup?: string[];
  staffsConditionGroup?: string[];
  strategy: IStrategy;
}
export interface ISkuForProducts {
  sku: ISkuParams;
  quantity: number;
}

export interface IProductsResponse {
  paymentSettings: {
    price: number;
    installment: number;
  }[];
  serialNumber: string;
  name: string;
  price: number;
  status: string;
  promotions?: any[];
  duration: number;
  description: string;
  skus: ISkuForProducts[];
  isExternal: boolean;
  detailHtml: string;
  createdAt: number;
  id: string;
}

export interface IContract {
  mark: string;
  content: string;
}

export interface IContractQueryParams extends IPaginationParams {}

export interface IContractResponse {
  name: string;
  mark: string;
  editor: {
    id: string;
    name: string;
    staffNumber: string;
  };
  content: string;
  createdAt: number;
  id: string;
}
export interface IExtensionContract {
  versionNumber: string;
  mark: string;
  content: string;
}
export interface IExtensionContractResponse {
  versionNumber: string;
  mark: string;
  editor: {
    id: string;
    name: string;
    staffNumber: string;
  };
  content: string;
  createdAt: number;
  updatedAt: number;

  id: string;
}

export interface ICreateExtendedOrderPayload {
  contractExtensionId: string;
  order: string;
  status: orderState;
  expired: string;
  month: string;
  contract: string;
  payment: EPayment;
  paymentMethod: EPaymentFlow;
  installment: string;

  commodities: {
    plan: string;
    products: {
      product: string;
      quantity: number;
    }[];
    planPromotions?: string[];
  }[];
  uniformNumbers?: string;
  companyLetterhead?: string;
  companyAddress?: string;
  managers?: string[];
  invoice?: string;
}

export interface IUpdateExtendedOrderPayload {
  uniformNumbers?: string;
  companyLetterhead?: string;
  companyAddress?: string;
  invoice?: string;

  managers?: string[];
}
export interface TContractEmailVariableResponse {
  name: string;
  value: string;
}

export interface ISearchOrderListParams {
  skip?: number; // 頁數Offset
  limit?: number; // 顯示筆數
  status?: string; // 訂單狀態
  payment?: string; // 付款方式

  id?: string; // 訂單ID
  crmNo?: string; // CMS交編
  customerId?: string; // 客戶ID
  customerName?: string; // 客戶名稱
  // 顧問名稱

  dateType?: string; // 篩選時間類型
  startAt?: number; // 開始時間
  endAt?: number; // 結束時間

  source?: ESource; // 來源
}

export interface IPromotionsCanUseParams {
  productId?: string; // 產品ID
  planId?: string; // 方案ID
}

export interface ITransferParams {
  transferMoneyInfo: {
    bankName?: string;
    transferAccount?: string;
    transferDate: number;
  };
  status: string;
}

export interface IGetSkuFindAllResponse {
  activitySku: {
    freebies: {
      id: string;
    }[];
  };
}

export default (axios: AxiosInstance, electronicCommerce: string) => ({
  /**
   * 批次更新兌換請求
   */
  batchUpdateDelivery(params: TBatchUpdateDeliveryParams): Promise<any> {
    const formData = new FormData();
    formData.append('file', params.file);
    return axios.post(
      `${electronicCommerce}/backend/deliveries/batchUpdate`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
        responseType: 'blob',
      },
    );
  },

  exportDeliveryRecord(data: IExportDeliveryParams): Promise<any> {
    return axios.get(`${electronicCommerce}/backend/deliveries/exportExcel`, {
      params: data,
      responseType: 'blob',
    });
  },

  getDeliveryList(params: IDeliveryParams): TApiResponse<IDeliveryResponse> {
    return axios.get(`${electronicCommerce}/backend/deliveries`, { params });
  },
  // spu
  /**
   * 建立商品
   */
  createProducts(data: IProducts): any {
    return axios.post(`${electronicCommerce}/products`, data);
  },
  /**
   * 商品列表
   */
  getProductList(data: IProductListParams): any {
    return axios.get(`${electronicCommerce}/products`, { params: data });
  },
  /**
   * 取得單個商品
   */
  getSpuById(id: string): any {
    return axios.get(`${electronicCommerce}/products/${id}`);
  },
  /**
   * 編輯商品
   */
  updateProductById(id: string, data: IProducts): any {
    return axios.patch(`${electronicCommerce}/products/${id}`, data);
  },
  // attributes type
  /**
   * 新增屬性類型選項
   */
  createAttributesTypes(data: ICreateAttributesTypesParams): any {
    return axios.post(`${electronicCommerce}/attributes-types`, data);
  },
  /**
   * 屬性類型列表
   */
  getAttributesTypesList(): any {
    return axios.get(`${electronicCommerce}/attributes-types`);
  },
  // attributes
  /**
   * 新增屬性
   */
  createAttributes(data: ICreateAttributesParams): any {
    return axios.post(`${electronicCommerce}/attributes`, data);
  },
  /**
   * 屬性列表
   */
  getAteAttributesList(data: IAttributesListParams): any {
    return axios.get(`${electronicCommerce}/attributes`, { params: data });
  },
  /**
   * 屬性列表
   */
  getAttributesListByNames(data: IAttributesListByNamesParams): any {
    return axios.get(`${electronicCommerce}/attributes/attributesByNames`, {
      params: data,
    });
  },
  /**
   * 更新屬性
   */
  updateAttributes(id: string, data: { name: string }): any {
    return axios.patch(`${electronicCommerce}/attributes/${id}`, data);
  },
  /**
   * 多的名稱取得屬性列表
   */
  getAttributesByNames(data: {
    names: string[];
  }): TApiResponse<ISkuAttribute[]> {
    return axios.get(`${electronicCommerce}/attributes/attributesByNames`, {
      params: data,
    });
  },

  // sku
  /**
   * 建立SKU
   */
  createSku(data: ISkuParams): any {
    return axios.post(`${electronicCommerce}/sku`, data);
  },
  /**
   * 取得SKU清單
   */
  getSkuList(data: ISkuListParams): TApiResponse<{
    sku: ISku[];
    total: number;
  }> {
    return axios.get(`${electronicCommerce}/sku`, { params: data });
  },
  /**
   * 取得單個SKU
   */
  getSkuById(id: string): TApiResponse<ISku> {
    return axios.get(`${electronicCommerce}/sku/${id}`);
  },
  /**
   * 編輯SKU
   */
  updateSkuById(id: string, data: ISkuParams): any {
    return axios.patch(`${electronicCommerce}/sku/${id}`, data);
  },
  /**
   * 開關SKU
   */
  switchStatusSkuById(id: string, data: ISkuParams): any {
    return axios.patch(`${electronicCommerce}/sku/statusSwitch/${id}`, data);
  },
  /**
   * sku列表 去除屬性：活動
   */
  liveCourseWithoutAttributesActivity(data?: {
    category: string;
  }): TApiResponse<{
    sku: ISku[];
    total: number;
  }> {
    return axios.get(
      `${electronicCommerce}/sku/findAll/liveCourseWithoutAttributesActivity`,
      { params: data },
    );
  },
  /**
   * sku列表-取得該sku以及課程類型為活動的sku
   */
  getSkuFindAll(skuId: string): TApiResponse<IGetSkuFindAllResponse> {
    return axios.get(`${electronicCommerce}/sku/findAll/attributes/${skuId}`);
  },
  // sku end
  // plan
  /**
   * 取得方案列表
   */
  getPlan(
    queryParams?: IPlanQueryParams,
  ): TApiResponse<{ total: number; plans: IPlan[] }> {
    return axios.get(`${electronicCommerce}/plan`, {
      params: queryParams,
    });
  },

  getPlanForStaff(
    queryParams?: IPlanQueryParams,
  ): TApiResponse<{ total: number; plans: IPlan[] }> {
    return axios.get(`${electronicCommerce}/plan/forStaff`, {
      params: queryParams,
    });
  },

  getPlanById(id?: string): TApiResponse<IPlan> {
    return axios.get(`${electronicCommerce}/plan/${id}`);
  },

  createPlan(data: IPlanParams): TApiResponse<IPlan> {
    return axios.post(`${electronicCommerce}/plan`, data);
  },

  editPlan(
    id: string,
    data: PartialBy<IPlanParams, 'source'>,
  ): TApiResponse<IPlan> {
    return axios.patch(`${electronicCommerce}/plan/${id}`, data);
  },

  // plan end

  // promotion
  /**
   * 取得促銷列表
   */
  getPromotionList(
    queryParams?: IPromotionQueryParams,
  ): TApiResponse<{ total: number; products: IPromotion[] }> {
    return axios.get(`${electronicCommerce}/promotions`, {
      params: queryParams,
    });
  },

  getPromotionById(id: string): TApiResponse<IPromotion> {
    return axios.get(`${electronicCommerce}/promotions/${id}`);
  },

  createPromotion(data: IPromotionParams): TApiResponse<IPromotion> {
    return axios.post(`${electronicCommerce}/promotions`, data);
  },

  updatePromotionById(id: string, data: IPromotionParams): any {
    return axios.patch(`${electronicCommerce}/promotions/${id}`, data);
  },
  /**
   * 取得 單一產品/方案 可使用之促銷列表
   */
  getPromotionsCanUse(data: IPromotionsCanUseParams): TApiResponse<any> {
    return axios.get(`${electronicCommerce}/promotions/can-use`, {
      params: data,
    });
  },

  // promotion end

  // product start
  getProducts(
    queryParams?: IProductsQueryParams,
  ): TApiResponse<{ total: number; products: IProductsResponse[] }> {
    return axios.get(`${electronicCommerce}/products`, {
      params: queryParams,
    });
  },
  // product end
  // setting contract start
  /**
   * 取得合約列表
   */
  getContracts(
    queryParams?: IContractQueryParams,
  ): TApiResponse<{ total: number; contracts: IContractResponse[] }> {
    return axios.get(`${electronicCommerce}/contracts/template`, {
      params: queryParams,
    });
  },
  /**
   * 修訂合約
   * @param data
   */
  amendmentContract(data: IContract): TApiResponse<IContractResponse> {
    return axios.post(`${electronicCommerce}/contracts/template`, data);
  },
  /**
   * 獲取最新合約
   */
  getLatestContract(): TApiResponse<IContractResponse> {
    return axios.get(`${electronicCommerce}/contracts/template/latest`);
  },
  /**
   * 取得完整合約內容
   */
  getContractById(id: string): TApiResponse<any> {
    return axios.get(`${electronicCommerce}/contracts/${id}`);
  },
  /**
   * 創建PDF & 寄信
   */
  getSendContractAndCreatePdf(data: any): TApiResponse<any> {
    return axios.post(`${electronicCommerce}/contracts/send`, data);
  },
  // contract end

  // setting mail start
  /**
   * 取得信箱列表
   */
  getMailSetting(): any {
    return axios.get(`${electronicCommerce}/email-settings/latest`);
  },
  /**
   * 取得信箱列表
   */
  updateMailSetting(id: string, data: TMailSetting): any {
    return axios.patch(`${electronicCommerce}/email-settings/${id}`, data);
  },
  /**
   * 獲取合約 email 變數列表
   */
  getContractEmailVariable(): TApiResponse<TContractEmailVariableResponse[]> {
    return axios.get(`${electronicCommerce}/email-settings/emailVariables`);
  },

  /**
   * 獲取展延合約 email 變數列表
   */
  getExtensionContractEmailVariable(): TApiResponse<
    TContractEmailVariableResponse[]
  > {
    return axios.get(
      `${electronicCommerce}/email-settings/contracts-extension/emailVariables`,
    );
  },
  // setting paymentFlow start
  /**
   * 取得金流設定
   */
  getPaymentFlowSetting(): TApiResponse<TPaymentSettingResponseDto> {
    return axios.get(`${electronicCommerce}/payment-settings/latest`);
  },
  /**
   * 更新金流設定
   */
  updatePaymentFlowSetting(
    id: string,
    data: TPaymentSettingDto,
  ): TApiResponse<TPaymentSettingResponseDto> {
    return axios.patch(`${electronicCommerce}/payment-settings/${id}`, data);
  },
  /**
   * 取得展延合約列表
   */
  getExtensionContracts(queryParams?: IContractQueryParams): TApiResponse<{
    total: number;
    contractExtensionSettings: IExtensionContractResponse[];
  }> {
    return axios.get(
      `${electronicCommerce}/backend/contract-extension-settings`,
      {
        params: queryParams,
      },
    );
  },
  /**
   * 修訂展延合約
   * @param data
   */
  amendmentExtensionContract(
    data: IExtensionContract,
  ): TApiResponse<IExtensionContractResponse> {
    return axios.post(
      `${electronicCommerce}/backend/contract-extension-settings`,
      data,
    );
  },
  /**
   * 獲取最新展延合約
   */
  getLatestExtensionContract(): TApiResponse<IExtensionContractResponse> {
    return axios.get(
      `${electronicCommerce}/backend/contract-extension-settings/latest`,
    );
  },

  // order
  /**
   * 建立 訂單列表
   */
  createOrders(data: ISearchOrderListParams): any {
    return axios.post(`${electronicCommerce}/orders`, {
      ...data,
      from: 'mms',
    });
  },
  /**
   * 取得 訂單列表
   */
  getOrders(data: any): any {
    return axios.get(`${electronicCommerce}/orders`, {
      params: data,
    });
  },
  /**
   * 取得 訂單列表(含部門)
   */
  getOrdersByMe(data: IOrderMeParams): TApiResponse<IOrderMeResponse> {
    return axios.get(`${electronicCommerce}/orders/me`, {
      params: data,
    });
  },
  /**
   * 取得 訂單列表報告
   */
  getOrdersReport(
    data: IOrderPeriodParam,
  ): TApiResponse<IOrdersReportResponse> {
    return axios.get(`${electronicCommerce}/orders/report`, { params: data });
  },
  /**
   * 取得 單一 訂單（新）
   */
  getOrdersById(id: string): TApiResponse<IOrder> {
    return axios.get(`${electronicCommerce}/orders/${id}`);
  },
  /**
   * 取得 單一 訂單（舊）
   */
  getOnePayOrdersById(id: string): any {
    return axios.get(`${electronicCommerce}/onePayOrder/${id}`);
  },
  /**
   * 編輯訂單
   */
  updateOrdersById(id: string, data: any): any {
    return axios.patch(`${electronicCommerce}/orders/${id}`, data);
  },
  /**
   * 編輯訂單 - 學霸
   */
  updateLearningBarOrderById(
    id: string,
    data: IUpdateLearningBarOrderByIdPayload,
  ): TApiResponse<IUpdateLearningBarOrderByIdResponse> {
    return axios.patch(`${electronicCommerce}/orders/learningBar/${id}`, data);
  },
  /**
   * 編輯訂單 - 補習班
   */
  updateTutorialOrderById(
    id: string,
    data: IUpdateTutorialOrderByIdPayload,
  ): TApiResponse<IUpdateTutorialOrderByIdResponse> {
    return axios.patch(`${electronicCommerce}/orders/tutorial/${id}`, data);
  },
  /**
   * 編輯訂單 - 學校
   */
  updateSchoolOrderById(
    id: string,
    data: IUpdateSchoolOrderByIdPayload,
  ): TApiResponse<IUpdateSchoolOrderByIdResponse> {
    return axios.patch(`${electronicCommerce}/orders/school/${id}`, data);
  },
  /**
   * 轉移訂單
   */
  transferOrder(id: string, data: ITransferOrderParams): TApiResponse<any> {
    return axios.post(`${electronicCommerce}/orders/${id}/transfer`, data);
  },

  /**
   * 編輯訂單 轉帳資料
   */
  updateTransferPaymentInfoById(
    id: string,
    data: ITransferParams,
  ): TApiResponse<any> {
    return axios.patch(
      `${electronicCommerce}/intermediate-payment/transfer/${id}`,
      data,
    );
  },
  /**
   * 取消訂單
   */
  cancelOrdersById(id: string): any {
    return axios.post(`${electronicCommerce}/orders/${id}/cancel`);
  },
  /**
   * 匯出訂單 excel
   */
  async exportOrder(data: any): Promise<any> {
    const link = document.createElement('a');

    const excel = await axios.get(`${electronicCommerce}/orders/exportExcel`, {
      params: data,
      responseType: 'blob',
    });

    if (!excel) {
      return false;
    }

    const blob = new Blob([excel as unknown as BlobPart]);
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.download = `order_${format(new Date(), 'yyyyMMdd')}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  },
  /**
   * 下載合約
   */
  async downloadContract(id: string): Promise<any> {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = `${electronicCommerce}/orders/${id}/contract`;
    link.download = `${id}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  },

  // orderRecords
  getOrderRecords(orderId: string): TApiResponse<IGetOrderRecordsResponse[]> {
    return axios.get(`${electronicCommerce}/orderRecords/${orderId}`);
  },

  // performance-ratio
  /**
   * 取得 單一訂單 業績配比
   */
  getOrdersRatioById(id: string): TApiResponse<IOrdersRatio> {
    return axios.get(`${electronicCommerce}/performance-ratio/info/${id}`);
  },
  /**
   * 新增 業績配比
   */
  updateOrdersRatio(id: string, data: any): TApiResponse<IOrdersRatio> {
    return axios.patch(`${electronicCommerce}/performance-ratio/${id}`, data);
  },

  // services
  /**
   * 檢視oneClubID 是否存在
   */
  getCheckOneClubID(id: string): any {
    return axios.get(`${electronicCommerce}/services/${id}`);
  },

  // intermediate-payment
  /**
   * 查詢金流訂單詳細
   */
  getIntermediatePaymentById(
    id: string,
    data: { type: string; amount: number },
  ): any {
    return axios.post(`${electronicCommerce}/intermediate-payment/${id}`, data);
  },

  /**
   * 建立 展延訂單
   */
  createExtendedOrders(data: ICreateExtendedOrderPayload): any {
    return axios.post(`${electronicCommerce}/backend/contracts-extension`, {
      ...data,
    });
  },
  updateExtendedOrder(
    id: string,
    data: IUpdateExtendedOrderPayload,
  ): TApiResponse<any> {
    return axios.patch(
      `${electronicCommerce}/backend/contracts-extension/${id}`,
      data,
    );
  },
  /**
   * 取得 展延訂單 by id
   */
  getExtendedOrderById(id: string): any {
    return axios.get(`${electronicCommerce}/backend/contracts-extension/${id}`);
  },
  /**
   * 取得 展延訂單 by os order id
   */
  getExtendedOrderByOsId(id: string): any {
    return axios.get(
      `${electronicCommerce}/backend/contracts-extension/${id}/contracts-extensionId`,
    );
  },
  /**
   * 取得 展延訂單 紀錄
   */
  getExtendedOrderRecords(id: string): any {
    return axios.get(
      `${electronicCommerce}/backend/contract-extension-records/${id}`,
    );
  },
  /**
   * 註冊 FCM KEY
   */
  postSetFCMKey(token: any) {
    return axios.post(`${electronicCommerce}/notify/subscribeTopic`, {
      topic: 'deal',
      token,
    });
  },
  /**
   * 取得多筆訂單業務業績分配
   */
  getPerformanceRatioInfos(
    orderIds: string[],
  ): TApiResponse<IOrderRatioDataResponse[]> {
    return axios.post(
      `${electronicCommerce}/performance-ratio/infos`,
      orderIds,
    );
  },
  /**
   * 取得會員下的訂單
   */
  getOrdersByCustomer(
    customerId: string,
    params: IGetOrdersByCustomerParams,
  ): TApiResponse<IGetOrdersByCustomerResponse> {
    return axios.get(
      `${electronicCommerce}/orders/findAllByCustomer/${customerId}`,
      {
        params,
      },
    );
  },

  /**
   * 取得展延訂單
   */
  getContractsExtensionOrdersList(
    params: IGetContractsExtensionOrdersParams,
  ): TApiResponse<IGetContractsExtensionOrdersResponse> {
    return axios.get(`${electronicCommerce}/backend/contracts-extension`, {
      params,
    });
  },

  /**
   * 取得預收收入列表
   */
  getUnearnedRevenueList(
    params: IGetUnearnedRevenueListParams,
  ): TApiResponse<IGetUnearnedRevenueListResponse> {
    return axios.get(`${electronicCommerce}/revenues`, { params });
  },

  /**
   * 取得單筆預收收入
   */
  getUnearnedRevenueById(
    id: string,
  ): TApiResponse<IGetUnearnedRevenueByIdResponse> {
    return axios.get(`${electronicCommerce}/revenues/${id}`);
  },
  /**
   * 取得總收入（預收/暫收/認列）
   */
  getRevenuesAmtTotal(
    params: IGetRevenuesAmtTotalParams,
  ): TApiResponse<TGetRevenuesAmtTotalResponse> {
    return axios.get(`${electronicCommerce}/revenues/amtTotal`, { params });
  },
  /**
   * 匯出預收帳款表/暫收帳款表
   */
  async exportUnearnedRevenueList(
    params: IExportUnearnedRevenueListParams,
  ): Promise<boolean> {
    const excel = await axios.post(
      `${electronicCommerce}/revenues/generate/excel`,
      undefined,
      {
        params,
        responseType: 'blob',
      },
    );

    if (excel instanceof Blob) {
      const dateTimeStartAt = format(params.startAt, 'yyyyMMdd');
      const dateTimeEndAt = format(params.endAt, 'yyyyMMdd');
      const typeName = REVENUE_EXCEL_TYPE_LABEL[params.type]();
      const blob = new Blob([excel as unknown as BlobPart]);
      const source = URL.createObjectURL(blob);
      downloadFile(
        source,
        `${dateTimeStartAt}-${dateTimeEndAt}-${typeName}.xlsx`,
        EFILE_EXTENSION.XLSX,
      );
      return true;
    } else {
      return false;
    }
  },

  /**
   * 是否還有可兌換講義套數
   **/
  getExchangeItemsByOwnerId(
    params: TGetExchangeItemsByOwnerIdParams,
  ): TApiResponse<TGetExchangeItemsByOwnerIdResponse> {
    return axios.get(`${electronicCommerce}/backend/exchange-items-records`, {
      params,
    });
  },

  /**
   * 取得套數兌換異動紀錄
   */
  getExchangeItemsRecordLogsByRecordId(
    params: TGetExchangeItemsRecordLogsByIdParams,
  ): TApiResponse<TGetExchangeItemsRecordLogsByIdResponse> {
    return axios.get(
      `${electronicCommerce}/backend/exchange-items-record-logs`,
      {
        params,
      },
    );
  },

  /**
   * 異動總可兌換套數
   */
  updateExchangeItemsPackage(
    id: string,
    params: TUpdateExchangeItemsPackageParams,
  ): TApiResponse<TUpdateExchangeItemsPackageResponse> {
    return axios.patch(
      `${electronicCommerce}/backend/exchange-items-records/${id}`,
      params,
    );
  },

  /**
   * 建立物流單
   */
  createDeliveries(
    data: TCreateDeliveriesParams,
  ): TApiResponse<TCreateDeliveriesResponse> {
    return axios.post(`${electronicCommerce}/backend/deliveries`, data);
  },

  /**
   * 編輯物流單
   */
  editDeliveries(
    id: string,
    params: TCreateDeliveriesParams,
  ): TApiResponse<any> {
    return axios.patch(
      `${electronicCommerce}/backend/deliveries/${id}`,
      params,
    );
  },

  /**
   * 查詢單筆物流單
   */
  getDeliveriesById(id: string): TApiResponse<TCreateDeliveriesResponse> {
    return axios.get(`${electronicCommerce}/backend/deliveries/${id}`);
  },

  /**
   * 取得物流單異動紀錄
   */
  getDeliveriesRecordsById(
    params: TGetDeliveriesRecordsParams,
  ): TApiResponse<TDeliveryRecordsResponse> {
    return axios.get(`${electronicCommerce}/backend/delivery-events`, {
      params,
    });
  },

  /**
   * 後台 -> 會員暫緩領取講義
   */
  editCustomerTextbookYearAndSemester(
    data: TEditCustomerTextbookYearAndSemesterParams,
  ): TApiResponse<TEditCustomerTextbookYearAndSemesterResponse> {
    return axios.post(
      `${electronicCommerce}/backend/exchange-items-records/suspend`,
      data,
    );
  },

  /**
   * 建立退費單
   */
  createOrderRefund(
    data: ICreateOrderRefundParams,
  ): TApiResponse<IOrderRefundResponse> {
    const formData = new FormData();
    const { signedContractFiles, otherAttachmentsFiles, ...restData } = data;
    signedContractFiles.forEach(file => {
      formData.append(
        ContractRefundAttachmentCategory.SIGNATURE_ATTACHMENT_FILES,
        file,
      );
    });
    otherAttachmentsFiles.forEach(file => {
      formData.append(
        ContractRefundAttachmentCategory.OTHER_ATTACHMENT_FILES,
        file,
      );
    });
    Object.entries(restData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return axios.post(`${electronicCommerce}/backend/refund`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },

  /**
   * 更新退費單
   */
  updateOrderRefund(
    id: string,
    data: IPatchOrderRefundParams,
  ): TApiResponse<IOrderRefundResponse> {
    const formData = new FormData();
    const {
      signedContractFiles,
      otherAttachmentsFiles,
      updatedSignedContracts,
      updatedOtherAttachments,
      ...restData
    } = data;
    if (signedContractFiles) {
      signedContractFiles.forEach(file => {
        formData.append(
          ContractRefundAttachmentCategory.SIGNATURE_ATTACHMENT_FILES,
          file,
        );
      });
    }
    if (otherAttachmentsFiles) {
      otherAttachmentsFiles.forEach(file => {
        formData.append(
          ContractRefundAttachmentCategory.OTHER_ATTACHMENT_FILES,
          file,
        );
      });
    }
    if (updatedSignedContracts) {
      formData.append(
        'updatedSignedContracts',
        JSON.stringify(updatedSignedContracts),
      );
    }
    if (updatedOtherAttachments) {
      formData.append(
        'updatedOtherAttachments',
        JSON.stringify(updatedOtherAttachments),
      );
    }
    Object.entries(restData).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });

    return axios.patch(`${electronicCommerce}/backend/refund/${id}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },

  /**
   * 退費單列表
   */
  getOrderRefundList(
    data: IGetOrderRefundListParams,
  ): TApiResponse<IOrderRefundResponse[]> {
    return axios.get(`${electronicCommerce}/backend/refund`, { params: data });
  },

  /**
   * 取得單筆退費單
   */
  getOrderRefundById(id: string): TApiResponse<IOrderRefundResponse> {
    return axios.get(`${electronicCommerce}/backend/refund/${id}`);
  },

  /**
   * 建立合約展延契約
   */
  createContractExtension(
    data: ICreateContractExtensionParams,
  ): TApiResponse<IContractExtensionResponse> {
    const formData = new FormData();
    const { contractFiles, expired, order } = data;
    contractFiles?.forEach(file => {
      formData.append(ContractExtensionAttachmentCategory.CONTRACT_FILES, file);
    });

    formData.append('expired', expired.toISOString());

    formData.append('order', order);

    return axios.post(
      `${electronicCommerce}/backend/contracts-extension`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  },

  /**
   * 取得合約延展列表
   */
  getContractExtensionList(
    id: string,
  ): TApiResponse<IGetContractExtensionListResponse> {
    return axios.get(`${electronicCommerce}/backend/contracts-extension/${id}`);
  },

  /**
   * 更新展延合約
   */
  updateContractExtension(
    id: string,
    data: IUpdateContractExtensionPayload,
  ): TApiResponse<IGetContractExtensionListResponse> {
    return axios.patch(
      `${electronicCommerce}/backend/contracts-extension/${id}`,
      data,
    );
  },

  /**
   * 新增合約
   */
  addContractExtension(
    id: string,
    data: IAddContractExtensionPayload,
  ): TApiResponse<IGetContractExtensionListResponse> {
    const formData = new FormData();

    const { updatedContracts, contractFiles, expired } = data;

    contractFiles?.forEach(file => {
      formData.append(ContractExtensionAttachmentCategory.CONTRACT_FILES, file);
    });

    formData.append('expired', expired?.toISOString());

    formData.append(
      ContractExtensionAttachmentCategory.UPDATED_CONTRACTS,
      JSON.stringify(updatedContracts),
    );

    return axios.patch(
      `${electronicCommerce}/backend/contracts-extension/${id}`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  },

  /**
   * 取得業務成本列表
   */
  getPerformanceExpenseList(
    data: IGetPerformanceExpensePayload,
  ): TApiResponse<IGetPerformanceExpenseResponse> {
    return axios.get(`${electronicCommerce}/backend/performance-expense`, {
      params: data,
    });
  },

  /**
   * 匯出業務成本表
   */
  async exportPerformanceExpenseExcel(
    params: IExportPerformanceExpensePayload,
  ): Promise<any> {
    if (!params.startAt || !params.endAt) return false;

    const excel: Blob = await axios.get(
      `${electronicCommerce}/backend/performance-expense/export-excel`,
      {
        params,
        responseType: 'blob',
      },
    );

    const typeCount = {
      type: 'application/csv',
    };
    const blob = new Blob([excel as unknown as BlobPart], typeCount);
    const fileName = `${fToDate(params.startAt)}~${fToDate(
      params.endAt,
    )}業務成本表`;

    if (excel.type !== typeCount.type) {
      const fileReader = new FileReader();
      fileReader.readAsText(blob, 'UTF-8');

      fileReader.onload = function () {
        const error = JSON.parse(fileReader.result as string).error;
        if (error) {
          return false;
        }
      };
    } else {
      downloadXlsxFile(fileName, blob);
      return true;
    }
  },

  /**
   * 匯入業務成本表
   */
  async importPerformanceExpenseExcel(file: File): TApiResponse<string> {
    const form = new FormData();
    form.append('file', file);

    return await axios.post(
      `${electronicCommerce}/backend/performance-expense/excel-batch-update`,
      form,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  },
});
